:root {
  --theme-inter-black-36-09-font-family: "Inter", Helvetica;
  --theme-inter-black-36-09-font-size: 36.0938px;
  --theme-inter-black-36-09-font-style: normal;
  --theme-inter-black-36-09-font-weight: 900;
  --theme-inter-black-36-09-letter-spacing: 0px;
  --theme-inter-black-36-09-line-height: 48px;
  --theme-inter-black-36-25-font-family: "Inter", Helvetica;
  --theme-inter-black-36-25-font-size: 36.25px;
  --theme-inter-black-36-25-font-style: normal;
  --theme-inter-black-36-25-font-weight: 900;
  --theme-inter-black-36-25-letter-spacing: 0px;
  --theme-inter-black-36-25-line-height: 48px;
  --theme-inter-black-37-03-font-family: "Inter", Helvetica;
  --theme-inter-black-37-03-font-size: 37.0313px;
  --theme-inter-black-37-03-font-style: normal;
  --theme-inter-black-37-03-font-weight: 900;
  --theme-inter-black-37-03-letter-spacing: 0px;
  --theme-inter-black-37-03-line-height: 48px;
  --theme-inter-black-37-5-font-family: "Inter", Helvetica;
  --theme-inter-black-37-5-font-size: 37.5px;
  --theme-inter-black-37-5-font-style: normal;
  --theme-inter-black-37-5-font-weight: 900;
  --theme-inter-black-37-5-letter-spacing: 0px;
  --theme-inter-black-37-5-line-height: 48px;
  --theme-inter-black-37-66-font-family: "Inter", Helvetica;
  --theme-inter-black-37-66-font-size: 37.6563px;
  --theme-inter-black-37-66-font-style: normal;
  --theme-inter-black-37-66-font-weight: 900;
  --theme-inter-black-37-66-letter-spacing: 0px;
  --theme-inter-black-37-66-line-height: 48px;
  --theme-inter-black-38-44-font-family: "Inter", Helvetica;
  --theme-inter-black-38-44-font-size: 38.4375px;
  --theme-inter-black-38-44-font-style: normal;
  --theme-inter-black-38-44-font-weight: 900;
  --theme-inter-black-38-44-letter-spacing: 0px;
  --theme-inter-black-38-44-line-height: 48px;
  --theme-inter-black-40-font-family: "Inter", Helvetica;
  --theme-inter-black-40-font-size: 40px;
  --theme-inter-black-40-font-style: normal;
  --theme-inter-black-40-font-weight: 900;
  --theme-inter-black-40-letter-spacing: 0px;
  --theme-inter-black-40-line-height: 48px;
  --theme-inter-bold-10-14-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-14-font-size: 10.1406px;
  --theme-inter-bold-10-14-font-style: normal;
  --theme-inter-bold-10-14-font-weight: 700;
  --theme-inter-bold-10-14-letter-spacing: 0px;
  --theme-inter-bold-10-14-line-height: 12px;
  --theme-inter-bold-10-31-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-31-upper-font-size: 10.3125px;
  --theme-inter-bold-10-31-upper-font-style: normal;
  --theme-inter-bold-10-31-upper-font-weight: 700;
  --theme-inter-bold-10-31-upper-letter-spacing: 1px;
  --theme-inter-bold-10-31-upper-line-height: 17.14px;
  --theme-inter-bold-10-5-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-5-upper-font-size: 10.5px;
  --theme-inter-bold-10-5-upper-font-style: normal;
  --theme-inter-bold-10-5-upper-font-weight: 700;
  --theme-inter-bold-10-5-upper-letter-spacing: 0px;
  --theme-inter-bold-10-5-upper-line-height: 15px;
  --theme-inter-bold-10-69-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-69-upper-font-size: 10.6875px;
  --theme-inter-bold-10-69-upper-font-style: normal;
  --theme-inter-bold-10-69-upper-font-weight: 700;
  --theme-inter-bold-10-69-upper-letter-spacing: 0px;
  --theme-inter-bold-10-69-upper-line-height: 15px;
  --theme-inter-bold-10-87-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-87-upper-font-size: 10.8672px;
  --theme-inter-bold-10-87-upper-font-style: normal;
  --theme-inter-bold-10-87-upper-font-weight: 700;
  --theme-inter-bold-10-87-upper-letter-spacing: 1px;
  --theme-inter-bold-10-87-upper-line-height: 48px;
  --theme-inter-bold-10-88-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-88-font-size: 10.875px;
  --theme-inter-bold-10-88-font-style: normal;
  --theme-inter-bold-10-88-font-weight: 700;
  --theme-inter-bold-10-88-letter-spacing: 0px;
  --theme-inter-bold-10-88-line-height: 14px;
  --theme-inter-bold-10-88-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-88-upper-font-size: 10.875px;
  --theme-inter-bold-10-88-upper-font-style: normal;
  --theme-inter-bold-10-88-upper-font-weight: 700;
  --theme-inter-bold-10-88-upper-letter-spacing: 0px;
  --theme-inter-bold-10-88-upper-line-height: 15px;
  --theme-inter-bold-10-97-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-97-upper-font-size: 10.9688px;
  --theme-inter-bold-10-97-upper-font-style: normal;
  --theme-inter-bold-10-97-upper-font-weight: 700;
  --theme-inter-bold-10-97-upper-letter-spacing: 1px;
  --theme-inter-bold-10-97-upper-line-height: 20px;
  --theme-inter-bold-10-font-family: "Inter", Helvetica;
  --theme-inter-bold-10-font-size: 10px;
  --theme-inter-bold-10-font-style: normal;
  --theme-inter-bold-10-font-weight: 700;
  --theme-inter-bold-10-letter-spacing: 0px;
  --theme-inter-bold-10-line-height: 22px;
  --theme-inter-bold-11-06-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-06-font-size: 11.0625px;
  --theme-inter-bold-11-06-font-style: normal;
  --theme-inter-bold-11-06-font-weight: 700;
  --theme-inter-bold-11-06-letter-spacing: 0px;
  --theme-inter-bold-11-06-line-height: 17.14px;
  --theme-inter-bold-11-06-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-06-upper-font-size: 11.0625px;
  --theme-inter-bold-11-06-upper-font-style: normal;
  --theme-inter-bold-11-06-upper-font-weight: 700;
  --theme-inter-bold-11-06-upper-letter-spacing: 0px;
  --theme-inter-bold-11-06-upper-line-height: 15px;
  --theme-inter-bold-11-07-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-07-upper-font-size: 11.0703px;
  --theme-inter-bold-11-07-upper-font-style: normal;
  --theme-inter-bold-11-07-upper-font-weight: 700;
  --theme-inter-bold-11-07-upper-letter-spacing: 1px;
  --theme-inter-bold-11-07-upper-line-height: 30px;
  --theme-inter-bold-11-17-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-17-upper-font-size: 11.1719px;
  --theme-inter-bold-11-17-upper-font-style: normal;
  --theme-inter-bold-11-17-upper-font-weight: 700;
  --theme-inter-bold-11-17-upper-letter-spacing: 1px;
  --theme-inter-bold-11-17-upper-line-height: 30px;
  --theme-inter-bold-11-25-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-25-font-size: 11.25px;
  --theme-inter-bold-11-25-font-style: normal;
  --theme-inter-bold-11-25-font-weight: 700;
  --theme-inter-bold-11-25-letter-spacing: 0px;
  --theme-inter-bold-11-25-line-height: 14px;
  --theme-inter-bold-11-27-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-27-upper-font-size: 11.2734px;
  --theme-inter-bold-11-27-upper-font-style: normal;
  --theme-inter-bold-11-27-upper-font-weight: 700;
  --theme-inter-bold-11-27-upper-letter-spacing: 1px;
  --theme-inter-bold-11-27-upper-line-height: 30px;
  --theme-inter-bold-11-38-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-38-upper-font-size: 11.375px;
  --theme-inter-bold-11-38-upper-font-style: normal;
  --theme-inter-bold-11-38-upper-font-weight: 700;
  --theme-inter-bold-11-38-upper-letter-spacing: 1px;
  --theme-inter-bold-11-38-upper-line-height: 30px;
  --theme-inter-bold-11-44-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-44-font-size: 11.4375px;
  --theme-inter-bold-11-44-font-style: normal;
  --theme-inter-bold-11-44-font-weight: 700;
  --theme-inter-bold-11-44-letter-spacing: 0px;
  --theme-inter-bold-11-44-line-height: 14px;
  --theme-inter-bold-11-44-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-44-upper-font-size: 11.4375px;
  --theme-inter-bold-11-44-upper-font-style: normal;
  --theme-inter-bold-11-44-upper-font-weight: 700;
  --theme-inter-bold-11-44-upper-letter-spacing: 1px;
  --theme-inter-bold-11-44-upper-line-height: 16px;
  --theme-inter-bold-11-58-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-58-upper-font-size: 11.5781px;
  --theme-inter-bold-11-58-upper-font-style: normal;
  --theme-inter-bold-11-58-upper-font-weight: 700;
  --theme-inter-bold-11-58-upper-letter-spacing: 1px;
  --theme-inter-bold-11-58-upper-line-height: 30px;
  --theme-inter-bold-11-63-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-63-font-size: 11.625px;
  --theme-inter-bold-11-63-font-style: normal;
  --theme-inter-bold-11-63-font-weight: 700;
  --theme-inter-bold-11-63-letter-spacing: 0px;
  --theme-inter-bold-11-63-line-height: 14px;
  --theme-inter-bold-11-68-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-68-upper-font-size: 11.6797px;
  --theme-inter-bold-11-68-upper-font-style: normal;
  --theme-inter-bold-11-68-upper-font-weight: 700;
  --theme-inter-bold-11-68-upper-letter-spacing: 1px;
  --theme-inter-bold-11-68-upper-line-height: 48px;
  --theme-inter-bold-11-78-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-78-upper-font-size: 11.7813px;
  --theme-inter-bold-11-78-upper-font-style: normal;
  --theme-inter-bold-11-78-upper-font-weight: 700;
  --theme-inter-bold-11-78-upper-letter-spacing: 1px;
  --theme-inter-bold-11-78-upper-line-height: 48px;
  --theme-inter-bold-11-81-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-81-font-size: 11.8125px;
  --theme-inter-bold-11-81-font-style: normal;
  --theme-inter-bold-11-81-font-weight: 700;
  --theme-inter-bold-11-81-letter-spacing: 0px;
  --theme-inter-bold-11-81-line-height: 24px;
  --theme-inter-bold-11-92-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-92-font-size: 11.9219px;
  --theme-inter-bold-11-92-font-style: normal;
  --theme-inter-bold-11-92-font-weight: 700;
  --theme-inter-bold-11-92-letter-spacing: 0px;
  --theme-inter-bold-11-92-line-height: 21px;
  --theme-inter-bold-11-98-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-11-98-upper-font-size: 11.9844px;
  --theme-inter-bold-11-98-upper-font-style: normal;
  --theme-inter-bold-11-98-upper-font-weight: 700;
  --theme-inter-bold-11-98-upper-letter-spacing: 1px;
  --theme-inter-bold-11-98-upper-line-height: 48px;
  --theme-inter-bold-12-03-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-03-font-size: 12.0313px;
  --theme-inter-bold-12-03-font-style: normal;
  --theme-inter-bold-12-03-font-weight: 700;
  --theme-inter-bold-12-03-letter-spacing: 0px;
  --theme-inter-bold-12-03-line-height: 21px;
  --theme-inter-bold-12-14-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-14-font-size: 12.1406px;
  --theme-inter-bold-12-14-font-style: normal;
  --theme-inter-bold-12-14-font-weight: 700;
  --theme-inter-bold-12-14-letter-spacing: 0px;
  --theme-inter-bold-12-14-line-height: 21px;
  --theme-inter-bold-12-25-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-25-font-size: 12.25px;
  --theme-inter-bold-12-25-font-style: normal;
  --theme-inter-bold-12-25-font-weight: 700;
  --theme-inter-bold-12-25-letter-spacing: 0px;
  --theme-inter-bold-12-25-line-height: 21px;
  --theme-inter-bold-12-47-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-47-font-size: 12.4688px;
  --theme-inter-bold-12-47-font-style: normal;
  --theme-inter-bold-12-47-font-weight: 700;
  --theme-inter-bold-12-47-letter-spacing: 0px;
  --theme-inter-bold-12-47-line-height: 21px;
  --theme-inter-bold-12-58-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-58-font-size: 12.5781px;
  --theme-inter-bold-12-58-font-style: normal;
  --theme-inter-bold-12-58-font-weight: 700;
  --theme-inter-bold-12-58-letter-spacing: 0px;
  --theme-inter-bold-12-58-line-height: 21px;
  --theme-inter-bold-12-69-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-69-font-size: 12.6875px;
  --theme-inter-bold-12-69-font-style: normal;
  --theme-inter-bold-12-69-font-weight: 700;
  --theme-inter-bold-12-69-letter-spacing: 0px;
  --theme-inter-bold-12-69-line-height: 21px;
  --theme-inter-bold-12-8-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-8-font-size: 12.7969px;
  --theme-inter-bold-12-8-font-style: normal;
  --theme-inter-bold-12-8-font-weight: 700;
  --theme-inter-bold-12-8-letter-spacing: 0px;
  --theme-inter-bold-12-8-line-height: 21px;
  --theme-inter-bold-12-91-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-91-font-size: 12.9063px;
  --theme-inter-bold-12-91-font-style: normal;
  --theme-inter-bold-12-91-font-weight: 700;
  --theme-inter-bold-12-91-letter-spacing: 0px;
  --theme-inter-bold-12-91-line-height: 21px;
  --theme-inter-bold-12-font-family: "Inter", Helvetica;
  --theme-inter-bold-12-font-size: 12px;
  --theme-inter-bold-12-font-style: normal;
  --theme-inter-bold-12-font-weight: 700;
  --theme-inter-bold-12-letter-spacing: 0px;
  --theme-inter-bold-12-line-height: normal;
  --theme-inter-bold-13-13-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-13-font-size: 13.125px;
  --theme-inter-bold-13-13-font-style: normal;
  --theme-inter-bold-13-13-font-weight: 700;
  --theme-inter-bold-13-13-letter-spacing: 0px;
  --theme-inter-bold-13-13-line-height: 24px;
  --theme-inter-bold-13-23-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-23-font-size: 13.2344px;
  --theme-inter-bold-13-23-font-style: normal;
  --theme-inter-bold-13-23-font-weight: 700;
  --theme-inter-bold-13-23-letter-spacing: 0px;
  --theme-inter-bold-13-23-line-height: 20px;
  --theme-inter-bold-13-34-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-34-font-size: 13.3438px;
  --theme-inter-bold-13-34-font-style: normal;
  --theme-inter-bold-13-34-font-weight: 700;
  --theme-inter-bold-13-34-letter-spacing: 0px;
  --theme-inter-bold-13-34-line-height: 20px;
  --theme-inter-bold-13-38-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-38-upper-font-size: 13.375px;
  --theme-inter-bold-13-38-upper-font-style: normal;
  --theme-inter-bold-13-38-upper-font-weight: 700;
  --theme-inter-bold-13-38-upper-letter-spacing: 1px;
  --theme-inter-bold-13-38-upper-line-height: 24px;
  --theme-inter-bold-13-45-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-45-font-size: 13.4531px;
  --theme-inter-bold-13-45-font-style: normal;
  --theme-inter-bold-13-45-font-weight: 700;
  --theme-inter-bold-13-45-letter-spacing: 0px;
  --theme-inter-bold-13-45-line-height: 18px;
  --theme-inter-bold-13-56-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-56-font-size: 13.5625px;
  --theme-inter-bold-13-56-font-style: normal;
  --theme-inter-bold-13-56-font-weight: 700;
  --theme-inter-bold-13-56-letter-spacing: 0px;
  --theme-inter-bold-13-56-line-height: 20px;
  --theme-inter-bold-13-63-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-63-font-size: 13.625px;
  --theme-inter-bold-13-63-font-style: normal;
  --theme-inter-bold-13-63-font-weight: 700;
  --theme-inter-bold-13-63-letter-spacing: 0px;
  --theme-inter-bold-13-63-line-height: 20px;
  --theme-inter-bold-13-67-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-67-font-size: 13.6719px;
  --theme-inter-bold-13-67-font-style: normal;
  --theme-inter-bold-13-67-font-weight: 700;
  --theme-inter-bold-13-67-letter-spacing: 0px;
  --theme-inter-bold-13-67-line-height: 20px;
  --theme-inter-bold-13-75-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-75-font-size: 13.75px;
  --theme-inter-bold-13-75-font-style: normal;
  --theme-inter-bold-13-75-font-weight: 700;
  --theme-inter-bold-13-75-letter-spacing: 0px;
  --theme-inter-bold-13-75-line-height: 20px;
  --theme-inter-bold-13-75-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-75-upper-font-size: 13.75px;
  --theme-inter-bold-13-75-upper-font-style: normal;
  --theme-inter-bold-13-75-upper-font-weight: 700;
  --theme-inter-bold-13-75-upper-letter-spacing: 1px;
  --theme-inter-bold-13-75-upper-line-height: 24px;
  --theme-inter-bold-13-78-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-78-font-size: 13.7813px;
  --theme-inter-bold-13-78-font-style: normal;
  --theme-inter-bold-13-78-font-weight: 700;
  --theme-inter-bold-13-78-letter-spacing: 0px;
  --theme-inter-bold-13-78-line-height: 18px;
  --theme-inter-bold-13-88-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-88-font-size: 13.875px;
  --theme-inter-bold-13-88-font-style: normal;
  --theme-inter-bold-13-88-font-weight: 700;
  --theme-inter-bold-13-88-letter-spacing: 0px;
  --theme-inter-bold-13-88-line-height: 20px;
  --theme-inter-bold-13-89-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-89-font-size: 13.8906px;
  --theme-inter-bold-13-89-font-style: normal;
  --theme-inter-bold-13-89-font-weight: 700;
  --theme-inter-bold-13-89-letter-spacing: 0px;
  --theme-inter-bold-13-89-line-height: 20px;
  --theme-inter-bold-13-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-13-upper-font-size: 13px;
  --theme-inter-bold-13-upper-font-style: normal;
  --theme-inter-bold-13-upper-font-weight: 700;
  --theme-inter-bold-13-upper-letter-spacing: 1px;
  --theme-inter-bold-13-upper-line-height: 30px;
  --theme-inter-bold-14-13-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-13-font-size: 14.125px;
  --theme-inter-bold-14-13-font-style: normal;
  --theme-inter-bold-14-13-font-weight: 700;
  --theme-inter-bold-14-13-letter-spacing: 0px;
  --theme-inter-bold-14-13-line-height: 20px;
  --theme-inter-bold-14-13-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-13-upper-font-size: 14.125px;
  --theme-inter-bold-14-13-upper-font-style: normal;
  --theme-inter-bold-14-13-upper-font-weight: 700;
  --theme-inter-bold-14-13-upper-letter-spacing: 1px;
  --theme-inter-bold-14-13-upper-line-height: 24px;
  --theme-inter-bold-14-21-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-21-upper-font-size: 14.2109px;
  --theme-inter-bold-14-21-upper-font-style: normal;
  --theme-inter-bold-14-21-upper-font-weight: 700;
  --theme-inter-bold-14-21-upper-letter-spacing: 0px;
  --theme-inter-bold-14-21-upper-line-height: 30px;
  --theme-inter-bold-14-25-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-25-font-size: 14.25px;
  --theme-inter-bold-14-25-font-style: normal;
  --theme-inter-bold-14-25-font-weight: 700;
  --theme-inter-bold-14-25-letter-spacing: 0px;
  --theme-inter-bold-14-25-line-height: 20px;
  --theme-inter-bold-14-25-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-25-upper-font-size: 14.25px;
  --theme-inter-bold-14-25-upper-font-style: normal;
  --theme-inter-bold-14-25-upper-font-weight: 700;
  --theme-inter-bold-14-25-upper-letter-spacing: 1px;
  --theme-inter-bold-14-25-upper-line-height: 24px;
  --theme-inter-bold-14-34-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-34-font-size: 14.3438px;
  --theme-inter-bold-14-34-font-style: normal;
  --theme-inter-bold-14-34-font-weight: 700;
  --theme-inter-bold-14-34-letter-spacing: 0px;
  --theme-inter-bold-14-34-line-height: 18px;
  --theme-inter-bold-14-38-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-38-font-size: 14.375px;
  --theme-inter-bold-14-38-font-style: normal;
  --theme-inter-bold-14-38-font-weight: 700;
  --theme-inter-bold-14-38-letter-spacing: 0px;
  --theme-inter-bold-14-38-line-height: 20px;
  --theme-inter-bold-14-48-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-48-font-size: 14.4844px;
  --theme-inter-bold-14-48-font-style: normal;
  --theme-inter-bold-14-48-font-weight: 700;
  --theme-inter-bold-14-48-letter-spacing: 0px;
  --theme-inter-bold-14-48-line-height: 18px;
  --theme-inter-bold-14-48-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-48-upper-font-size: 14.4766px;
  --theme-inter-bold-14-48-upper-font-style: normal;
  --theme-inter-bold-14-48-upper-font-weight: 700;
  --theme-inter-bold-14-48-upper-letter-spacing: 0px;
  --theme-inter-bold-14-48-upper-line-height: 30px;
  --theme-inter-bold-14-63-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-63-upper-font-size: 14.625px;
  --theme-inter-bold-14-63-upper-font-style: normal;
  --theme-inter-bold-14-63-upper-font-weight: 700;
  --theme-inter-bold-14-63-upper-letter-spacing: 1px;
  --theme-inter-bold-14-63-upper-line-height: 24px;
  --theme-inter-bold-14-74-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-74-upper-font-size: 14.7422px;
  --theme-inter-bold-14-74-upper-font-style: normal;
  --theme-inter-bold-14-74-upper-font-weight: 700;
  --theme-inter-bold-14-74-upper-letter-spacing: 0px;
  --theme-inter-bold-14-74-upper-line-height: 30px;
  --theme-inter-bold-14-77-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-77-font-size: 14.7656px;
  --theme-inter-bold-14-77-font-style: normal;
  --theme-inter-bold-14-77-font-weight: 700;
  --theme-inter-bold-14-77-letter-spacing: 0px;
  --theme-inter-bold-14-77-line-height: 18px;
  --theme-inter-bold-14-88-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-88-upper-font-size: 14.875px;
  --theme-inter-bold-14-88-upper-font-style: normal;
  --theme-inter-bold-14-88-upper-font-weight: 700;
  --theme-inter-bold-14-88-upper-letter-spacing: 0px;
  --theme-inter-bold-14-88-upper-line-height: 30px;
  --theme-inter-bold-14-font-family: "Inter", Helvetica;
  --theme-inter-bold-14-font-size: 14px;
  --theme-inter-bold-14-font-style: normal;
  --theme-inter-bold-14-font-weight: 700;
  --theme-inter-bold-14-letter-spacing: 0px;
  --theme-inter-bold-14-line-height: 20px;
  --theme-inter-bold-15-01-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-01-upper-font-size: 15.0078px;
  --theme-inter-bold-15-01-upper-font-style: normal;
  --theme-inter-bold-15-01-upper-font-weight: 700;
  --theme-inter-bold-15-01-upper-letter-spacing: 0px;
  --theme-inter-bold-15-01-upper-line-height: 30px;
  --theme-inter-bold-15-05-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-05-font-size: 15.0469px;
  --theme-inter-bold-15-05-font-style: normal;
  --theme-inter-bold-15-05-font-weight: 700;
  --theme-inter-bold-15-05-letter-spacing: 0px;
  --theme-inter-bold-15-05-line-height: 25.71px;
  --theme-inter-bold-15-13-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-13-font-size: 15.125px;
  --theme-inter-bold-15-13-font-style: normal;
  --theme-inter-bold-15-13-font-weight: 700;
  --theme-inter-bold-15-13-letter-spacing: 0px;
  --theme-inter-bold-15-13-line-height: 24px;
  --theme-inter-bold-15-19-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-19-font-size: 15.1875px;
  --theme-inter-bold-15-19-font-style: normal;
  --theme-inter-bold-15-19-font-weight: 700;
  --theme-inter-bold-15-19-letter-spacing: 0px;
  --theme-inter-bold-15-19-line-height: 24px;
  --theme-inter-bold-15-19-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-19-upper-font-size: 15.1875px;
  --theme-inter-bold-15-19-upper-font-style: normal;
  --theme-inter-bold-15-19-upper-font-weight: 700;
  --theme-inter-bold-15-19-upper-letter-spacing: 1px;
  --theme-inter-bold-15-19-upper-line-height: 20px;
  --theme-inter-bold-15-25-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-25-font-size: 15.25px;
  --theme-inter-bold-15-25-font-style: normal;
  --theme-inter-bold-15-25-font-weight: 700;
  --theme-inter-bold-15-25-letter-spacing: 0px;
  --theme-inter-bold-15-25-line-height: 28px;
  --theme-inter-bold-15-33-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-33-font-size: 15.3281px;
  --theme-inter-bold-15-33-font-style: normal;
  --theme-inter-bold-15-33-font-weight: 700;
  --theme-inter-bold-15-33-letter-spacing: 0px;
  --theme-inter-bold-15-33-line-height: 24px;
  --theme-inter-bold-15-33-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-33-upper-font-size: 15.3281px;
  --theme-inter-bold-15-33-upper-font-style: normal;
  --theme-inter-bold-15-33-upper-font-weight: 700;
  --theme-inter-bold-15-33-upper-letter-spacing: 1px;
  --theme-inter-bold-15-33-upper-line-height: 20px;
  --theme-inter-bold-15-38-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-38-font-size: 15.375px;
  --theme-inter-bold-15-38-font-style: normal;
  --theme-inter-bold-15-38-font-weight: 700;
  --theme-inter-bold-15-38-letter-spacing: 0px;
  --theme-inter-bold-15-38-line-height: 24px;
  --theme-inter-bold-15-47-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-47-font-size: 15.4688px;
  --theme-inter-bold-15-47-font-style: normal;
  --theme-inter-bold-15-47-font-weight: 700;
  --theme-inter-bold-15-47-letter-spacing: 0px;
  --theme-inter-bold-15-47-line-height: 24px;
  --theme-inter-bold-15-47-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-47-upper-font-size: 15.4688px;
  --theme-inter-bold-15-47-upper-font-style: normal;
  --theme-inter-bold-15-47-upper-font-weight: 700;
  --theme-inter-bold-15-47-upper-letter-spacing: 1px;
  --theme-inter-bold-15-47-upper-line-height: 20px;
  --theme-inter-bold-15-5-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-5-font-size: 15.5px;
  --theme-inter-bold-15-5-font-style: normal;
  --theme-inter-bold-15-5-font-weight: 700;
  --theme-inter-bold-15-5-letter-spacing: 0px;
  --theme-inter-bold-15-5-line-height: 28px;
  --theme-inter-bold-15-61-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-61-font-size: 15.6094px;
  --theme-inter-bold-15-61-font-style: normal;
  --theme-inter-bold-15-61-font-weight: 700;
  --theme-inter-bold-15-61-letter-spacing: 0px;
  --theme-inter-bold-15-61-line-height: 25.71px;
  --theme-inter-bold-15-61-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-61-upper-font-size: 15.6094px;
  --theme-inter-bold-15-61-upper-font-style: normal;
  --theme-inter-bold-15-61-upper-font-weight: 700;
  --theme-inter-bold-15-61-upper-letter-spacing: 1px;
  --theme-inter-bold-15-61-upper-line-height: 20px;
  --theme-inter-bold-15-63-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-63-font-size: 15.625px;
  --theme-inter-bold-15-63-font-style: normal;
  --theme-inter-bold-15-63-font-weight: 700;
  --theme-inter-bold-15-63-letter-spacing: 0px;
  --theme-inter-bold-15-63-line-height: 24px;
  --theme-inter-bold-15-75-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-75-font-size: 15.75px;
  --theme-inter-bold-15-75-font-style: normal;
  --theme-inter-bold-15-75-font-weight: 700;
  --theme-inter-bold-15-75-letter-spacing: 0px;
  --theme-inter-bold-15-75-line-height: 24px;
  --theme-inter-bold-15-75-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-75-upper-font-size: 15.75px;
  --theme-inter-bold-15-75-upper-font-style: normal;
  --theme-inter-bold-15-75-upper-font-weight: 700;
  --theme-inter-bold-15-75-upper-letter-spacing: 1px;
  --theme-inter-bold-15-75-upper-line-height: 36px;
  --theme-inter-bold-15-88-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-88-font-size: 15.875px;
  --theme-inter-bold-15-88-font-style: normal;
  --theme-inter-bold-15-88-font-weight: 700;
  --theme-inter-bold-15-88-letter-spacing: 0px;
  --theme-inter-bold-15-88-line-height: 28px;
  --theme-inter-bold-15-89-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-89-font-size: 15.8906px;
  --theme-inter-bold-15-89-font-style: normal;
  --theme-inter-bold-15-89-font-weight: 700;
  --theme-inter-bold-15-89-letter-spacing: 0px;
  --theme-inter-bold-15-89-line-height: 24px;
  --theme-inter-bold-15-89-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-89-upper-font-size: 15.8906px;
  --theme-inter-bold-15-89-upper-font-style: normal;
  --theme-inter-bold-15-89-upper-font-weight: 700;
  --theme-inter-bold-15-89-upper-letter-spacing: 1px;
  --theme-inter-bold-15-89-upper-line-height: 20px;
  --theme-inter-bold-15-94-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-94-upper-font-size: 15.9375px;
  --theme-inter-bold-15-94-upper-font-style: normal;
  --theme-inter-bold-15-94-upper-font-weight: 700;
  --theme-inter-bold-15-94-upper-letter-spacing: 0px;
  --theme-inter-bold-15-94-upper-line-height: 24px;
  --theme-inter-bold-15-font-family: "Inter", Helvetica;
  --theme-inter-bold-15-font-size: 15px;
  --theme-inter-bold-15-font-style: normal;
  --theme-inter-bold-15-font-weight: 700;
  --theme-inter-bold-15-letter-spacing: 0px;
  --theme-inter-bold-15-line-height: 28px;
  --theme-inter-bold-16-03-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-03-font-size: 16.0313px;
  --theme-inter-bold-16-03-font-style: normal;
  --theme-inter-bold-16-03-font-weight: 700;
  --theme-inter-bold-16-03-letter-spacing: 0px;
  --theme-inter-bold-16-03-line-height: 24px;
  --theme-inter-bold-16-03-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-03-upper-font-size: 16.0313px;
  --theme-inter-bold-16-03-upper-font-style: normal;
  --theme-inter-bold-16-03-upper-font-weight: 700;
  --theme-inter-bold-16-03-upper-letter-spacing: 1px;
  --theme-inter-bold-16-03-upper-line-height: 20px;
  --theme-inter-bold-16-17-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-17-font-size: 16.1719px;
  --theme-inter-bold-16-17-font-style: normal;
  --theme-inter-bold-16-17-font-weight: 700;
  --theme-inter-bold-16-17-letter-spacing: 0px;
  --theme-inter-bold-16-17-line-height: 24px;
  --theme-inter-bold-16-17-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-17-upper-font-size: 16.1719px;
  --theme-inter-bold-16-17-upper-font-style: normal;
  --theme-inter-bold-16-17-upper-font-weight: 700;
  --theme-inter-bold-16-17-upper-letter-spacing: 1px;
  --theme-inter-bold-16-17-upper-line-height: 20px;
  --theme-inter-bold-16-45-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-45-font-size: 16.4531px;
  --theme-inter-bold-16-45-font-style: normal;
  --theme-inter-bold-16-45-font-weight: 700;
  --theme-inter-bold-16-45-letter-spacing: 0px;
  --theme-inter-bold-16-45-line-height: 25.71px;
  --theme-inter-bold-16-59-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-59-font-size: 16.5938px;
  --theme-inter-bold-16-59-font-style: normal;
  --theme-inter-bold-16-59-font-weight: 700;
  --theme-inter-bold-16-59-letter-spacing: 0px;
  --theme-inter-bold-16-59-line-height: 25.71px;
  --theme-inter-bold-16-59-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-59-upper-font-size: 16.5938px;
  --theme-inter-bold-16-59-upper-font-style: normal;
  --theme-inter-bold-16-59-upper-font-weight: 700;
  --theme-inter-bold-16-59-upper-letter-spacing: 1px;
  --theme-inter-bold-16-59-upper-line-height: 20px;
  --theme-inter-bold-16-72-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-72-upper-font-size: 16.7188px;
  --theme-inter-bold-16-72-upper-font-style: normal;
  --theme-inter-bold-16-72-upper-font-weight: 700;
  --theme-inter-bold-16-72-upper-letter-spacing: 0px;
  --theme-inter-bold-16-72-upper-line-height: 24px;
  --theme-inter-bold-16-73-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-73-font-size: 16.7344px;
  --theme-inter-bold-16-73-font-style: normal;
  --theme-inter-bold-16-73-font-weight: 700;
  --theme-inter-bold-16-73-letter-spacing: 0px;
  --theme-inter-bold-16-73-line-height: 28px;
  --theme-inter-bold-16-88-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-88-font-size: 16.875px;
  --theme-inter-bold-16-88-font-style: normal;
  --theme-inter-bold-16-88-font-weight: 700;
  --theme-inter-bold-16-88-letter-spacing: 0px;
  --theme-inter-bold-16-88-line-height: 24px;
  --theme-inter-bold-16-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-font-size: 16px;
  --theme-inter-bold-16-font-style: normal;
  --theme-inter-bold-16-font-weight: 700;
  --theme-inter-bold-16-letter-spacing: 0px;
  --theme-inter-bold-16-line-height: 28px;
  --theme-inter-bold-16-underline-font-family: "Inter", Helvetica;
  --theme-inter-bold-16-underline-font-size: 16px;
  --theme-inter-bold-16-underline-font-style: normal;
  --theme-inter-bold-16-underline-font-weight: 700;
  --theme-inter-bold-16-underline-letter-spacing: 0px;
  --theme-inter-bold-16-underline-line-height: 28px;
  --theme-inter-bold-17-02-font-family: "Inter", Helvetica;
  --theme-inter-bold-17-02-font-size: 17.0156px;
  --theme-inter-bold-17-02-font-style: normal;
  --theme-inter-bold-17-02-font-weight: 700;
  --theme-inter-bold-17-02-letter-spacing: 0px;
  --theme-inter-bold-17-02-line-height: 28px;
  --theme-inter-bold-17-16-font-family: "Inter", Helvetica;
  --theme-inter-bold-17-16-font-size: 17.1563px;
  --theme-inter-bold-17-16-font-style: normal;
  --theme-inter-bold-17-16-font-weight: 700;
  --theme-inter-bold-17-16-letter-spacing: 0px;
  --theme-inter-bold-17-16-line-height: 28px;
  --theme-inter-bold-17-3-font-family: "Inter", Helvetica;
  --theme-inter-bold-17-3-font-size: 17.2969px;
  --theme-inter-bold-17-3-font-style: normal;
  --theme-inter-bold-17-3-font-weight: 700;
  --theme-inter-bold-17-3-letter-spacing: 0px;
  --theme-inter-bold-17-3-line-height: 28px;
  --theme-inter-bold-17-44-font-family: "Inter", Helvetica;
  --theme-inter-bold-17-44-font-size: 17.4375px;
  --theme-inter-bold-17-44-font-style: normal;
  --theme-inter-bold-17-44-font-weight: 700;
  --theme-inter-bold-17-44-letter-spacing: 0px;
  --theme-inter-bold-17-44-line-height: 28px;
  --theme-inter-bold-17-58-font-family: "Inter", Helvetica;
  --theme-inter-bold-17-58-font-size: 17.5781px;
  --theme-inter-bold-17-58-font-style: normal;
  --theme-inter-bold-17-58-font-weight: 700;
  --theme-inter-bold-17-58-letter-spacing: 0px;
  --theme-inter-bold-17-58-line-height: 28px;
  --theme-inter-bold-17-72-font-family: "Inter", Helvetica;
  --theme-inter-bold-17-72-font-size: 17.7188px;
  --theme-inter-bold-17-72-font-style: normal;
  --theme-inter-bold-17-72-font-weight: 700;
  --theme-inter-bold-17-72-letter-spacing: 0px;
  --theme-inter-bold-17-72-line-height: 18px;
  --theme-inter-bold-17-86-font-family: "Inter", Helvetica;
  --theme-inter-bold-17-86-font-size: 17.8594px;
  --theme-inter-bold-17-86-font-style: normal;
  --theme-inter-bold-17-86-font-weight: 700;
  --theme-inter-bold-17-86-letter-spacing: 0px;
  --theme-inter-bold-17-86-line-height: 28px;
  --theme-inter-bold-18-91-font-family: "Inter", Helvetica;
  --theme-inter-bold-18-91-font-size: 18.9063px;
  --theme-inter-bold-18-91-font-style: normal;
  --theme-inter-bold-18-91-font-weight: 700;
  --theme-inter-bold-18-91-letter-spacing: 0px;
  --theme-inter-bold-18-91-line-height: 24px;
  --theme-inter-bold-18-font-family: "Inter", Helvetica;
  --theme-inter-bold-18-font-size: 18px;
  --theme-inter-bold-18-font-style: normal;
  --theme-inter-bold-18-font-weight: 700;
  --theme-inter-bold-18-letter-spacing: 2px;
  --theme-inter-bold-18-line-height: 24px;
  --theme-inter-bold-19-06-font-family: "Inter", Helvetica;
  --theme-inter-bold-19-06-font-size: 19.0625px;
  --theme-inter-bold-19-06-font-style: normal;
  --theme-inter-bold-19-06-font-weight: 700;
  --theme-inter-bold-19-06-letter-spacing: 0px;
  --theme-inter-bold-19-06-line-height: 24px;
  --theme-inter-bold-19-38-font-family: "Inter", Helvetica;
  --theme-inter-bold-19-38-font-size: 19.375px;
  --theme-inter-bold-19-38-font-style: normal;
  --theme-inter-bold-19-38-font-weight: 700;
  --theme-inter-bold-19-38-letter-spacing: 0px;
  --theme-inter-bold-19-38-line-height: 24px;
  --theme-inter-bold-19-53-font-family: "Inter", Helvetica;
  --theme-inter-bold-19-53-font-size: 19.5313px;
  --theme-inter-bold-19-53-font-style: normal;
  --theme-inter-bold-19-53-font-weight: 700;
  --theme-inter-bold-19-53-letter-spacing: 0px;
  --theme-inter-bold-19-53-line-height: 20px;
  --theme-inter-bold-19-69-font-family: "Inter", Helvetica;
  --theme-inter-bold-19-69-font-size: 19.6875px;
  --theme-inter-bold-19-69-font-style: normal;
  --theme-inter-bold-19-69-font-weight: 700;
  --theme-inter-bold-19-69-letter-spacing: 0px;
  --theme-inter-bold-19-69-line-height: 24px;
  --theme-inter-bold-19-84-font-family: "Inter", Helvetica;
  --theme-inter-bold-19-84-font-size: 19.8438px;
  --theme-inter-bold-19-84-font-style: normal;
  --theme-inter-bold-19-84-font-weight: 700;
  --theme-inter-bold-19-84-letter-spacing: 0px;
  --theme-inter-bold-19-84-line-height: 24px;
  --theme-inter-bold-20-63-font-family: "Inter", Helvetica;
  --theme-inter-bold-20-63-font-size: 20.625px;
  --theme-inter-bold-20-63-font-style: normal;
  --theme-inter-bold-20-63-font-weight: 700;
  --theme-inter-bold-20-63-letter-spacing: 0px;
  --theme-inter-bold-20-63-line-height: 30px;
  --theme-inter-bold-20-81-font-family: "Inter", Helvetica;
  --theme-inter-bold-20-81-font-size: 20.8125px;
  --theme-inter-bold-20-81-font-style: normal;
  --theme-inter-bold-20-81-font-weight: 700;
  --theme-inter-bold-20-81-letter-spacing: 0px;
  --theme-inter-bold-20-81-line-height: 34.29px;
  --theme-inter-bold-20-font-family: "Inter", Helvetica;
  --theme-inter-bold-20-font-size: 20px;
  --theme-inter-bold-20-font-style: normal;
  --theme-inter-bold-20-font-weight: 700;
  --theme-inter-bold-20-letter-spacing: 0px;
  --theme-inter-bold-20-line-height: 20px;
  --theme-inter-bold-21-19-font-family: "Inter", Helvetica;
  --theme-inter-bold-21-19-font-size: 21.1875px;
  --theme-inter-bold-21-19-font-style: normal;
  --theme-inter-bold-21-19-font-weight: 700;
  --theme-inter-bold-21-19-letter-spacing: 0px;
  --theme-inter-bold-21-19-line-height: 30px;
  --theme-inter-bold-21-38-font-family: "Inter", Helvetica;
  --theme-inter-bold-21-38-font-size: 21.375px;
  --theme-inter-bold-21-38-font-style: normal;
  --theme-inter-bold-21-38-font-weight: 700;
  --theme-inter-bold-21-38-letter-spacing: 0px;
  --theme-inter-bold-21-38-line-height: 30px;
  --theme-inter-bold-21-56-font-family: "Inter", Helvetica;
  --theme-inter-bold-21-56-font-size: 21.5625px;
  --theme-inter-bold-21-56-font-style: normal;
  --theme-inter-bold-21-56-font-weight: 700;
  --theme-inter-bold-21-56-letter-spacing: 0px;
  --theme-inter-bold-21-56-line-height: 28px;
  --theme-inter-bold-21-94-font-family: "Inter", Helvetica;
  --theme-inter-bold-21-94-font-size: 21.9375px;
  --theme-inter-bold-21-94-font-style: normal;
  --theme-inter-bold-21-94-font-weight: 700;
  --theme-inter-bold-21-94-letter-spacing: 0px;
  --theme-inter-bold-21-94-line-height: 30px;
  --theme-inter-bold-21-font-family: "Inter", Helvetica;
  --theme-inter-bold-21-font-size: 21px;
  --theme-inter-bold-21-font-style: normal;
  --theme-inter-bold-21-font-weight: 700;
  --theme-inter-bold-21-letter-spacing: 0px;
  --theme-inter-bold-21-line-height: 30px;
  --theme-inter-bold-22-13-font-family: "Inter", Helvetica;
  --theme-inter-bold-22-13-font-size: 22.125px;
  --theme-inter-bold-22-13-font-style: normal;
  --theme-inter-bold-22-13-font-weight: 700;
  --theme-inter-bold-22-13-letter-spacing: 0px;
  --theme-inter-bold-22-13-line-height: 24px;
  --theme-inter-bold-23-06-font-family: "Inter", Helvetica;
  --theme-inter-bold-23-06-font-size: 23.0625px;
  --theme-inter-bold-23-06-font-style: normal;
  --theme-inter-bold-23-06-font-weight: 700;
  --theme-inter-bold-23-06-letter-spacing: 0px;
  --theme-inter-bold-23-06-line-height: 30px;
  --theme-inter-bold-25-55-font-family: "Inter", Helvetica;
  --theme-inter-bold-25-55-font-size: 25.5469px;
  --theme-inter-bold-25-55-font-style: normal;
  --theme-inter-bold-25-55-font-weight: 700;
  --theme-inter-bold-25-55-letter-spacing: 0px;
  --theme-inter-bold-25-55-line-height: 40px;
  --theme-inter-bold-25-9-font-family: "Inter", Helvetica;
  --theme-inter-bold-25-9-font-size: 25.8984px;
  --theme-inter-bold-25-9-font-style: normal;
  --theme-inter-bold-25-9-font-weight: 700;
  --theme-inter-bold-25-9-letter-spacing: 0px;
  --theme-inter-bold-25-9-line-height: 40px;
  --theme-inter-bold-27-13-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-13-upper-font-size: 27.125px;
  --theme-inter-bold-27-13-upper-font-style: normal;
  --theme-inter-bold-27-13-upper-font-weight: 700;
  --theme-inter-bold-27-13-upper-letter-spacing: 0px;
  --theme-inter-bold-27-13-upper-line-height: 38px;
  --theme-inter-bold-27-38-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-38-upper-font-size: 27.375px;
  --theme-inter-bold-27-38-upper-font-style: normal;
  --theme-inter-bold-27-38-upper-font-weight: 700;
  --theme-inter-bold-27-38-upper-letter-spacing: 0px;
  --theme-inter-bold-27-38-upper-line-height: 38px;
  --theme-inter-bold-27-5-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-5-font-size: 27.5px;
  --theme-inter-bold-27-5-font-style: normal;
  --theme-inter-bold-27-5-font-weight: 700;
  --theme-inter-bold-27-5-letter-spacing: 0px;
  --theme-inter-bold-27-5-line-height: 40px;
  --theme-inter-bold-27-5-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-5-upper-font-size: 27.5px;
  --theme-inter-bold-27-5-upper-font-style: normal;
  --theme-inter-bold-27-5-upper-font-weight: 700;
  --theme-inter-bold-27-5-upper-letter-spacing: 0px;
  --theme-inter-bold-27-5-upper-line-height: 38px;
  --theme-inter-bold-27-63-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-63-font-size: 27.625px;
  --theme-inter-bold-27-63-font-style: normal;
  --theme-inter-bold-27-63-font-weight: 700;
  --theme-inter-bold-27-63-letter-spacing: 0px;
  --theme-inter-bold-27-63-line-height: 40px;
  --theme-inter-bold-27-63-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-63-upper-font-size: 27.625px;
  --theme-inter-bold-27-63-upper-font-style: normal;
  --theme-inter-bold-27-63-upper-font-weight: 700;
  --theme-inter-bold-27-63-upper-letter-spacing: 0px;
  --theme-inter-bold-27-63-upper-line-height: 38px;
  --theme-inter-bold-27-75-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-75-font-size: 27.75px;
  --theme-inter-bold-27-75-font-style: normal;
  --theme-inter-bold-27-75-font-weight: 700;
  --theme-inter-bold-27-75-letter-spacing: 0px;
  --theme-inter-bold-27-75-line-height: 36px;
  --theme-inter-bold-27-75-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-75-upper-font-size: 27.75px;
  --theme-inter-bold-27-75-upper-font-style: normal;
  --theme-inter-bold-27-75-upper-font-weight: 700;
  --theme-inter-bold-27-75-upper-letter-spacing: 0px;
  --theme-inter-bold-27-75-upper-line-height: 38px;
  --theme-inter-bold-27-88-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-88-font-size: 27.875px;
  --theme-inter-bold-27-88-font-style: normal;
  --theme-inter-bold-27-88-font-weight: 700;
  --theme-inter-bold-27-88-letter-spacing: 0px;
  --theme-inter-bold-27-88-line-height: 40px;
  --theme-inter-bold-27-88-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-27-88-upper-font-size: 27.875px;
  --theme-inter-bold-27-88-upper-font-style: normal;
  --theme-inter-bold-27-88-upper-font-weight: 700;
  --theme-inter-bold-27-88-upper-letter-spacing: 0px;
  --theme-inter-bold-27-88-upper-line-height: 38px;
  --theme-inter-bold-28-38-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-28-38-upper-font-size: 28.375px;
  --theme-inter-bold-28-38-upper-font-style: normal;
  --theme-inter-bold-28-38-upper-font-weight: 700;
  --theme-inter-bold-28-38-upper-letter-spacing: 0px;
  --theme-inter-bold-28-38-upper-line-height: 38px;
  --theme-inter-bold-28-75-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-28-75-upper-font-size: 28.75px;
  --theme-inter-bold-28-75-upper-font-style: normal;
  --theme-inter-bold-28-75-upper-font-weight: 700;
  --theme-inter-bold-28-75-upper-letter-spacing: 0px;
  --theme-inter-bold-28-75-upper-line-height: 38px;
  --theme-inter-bold-28-88-font-family: "Inter", Helvetica;
  --theme-inter-bold-28-88-font-size: 28.875px;
  --theme-inter-bold-28-88-font-style: normal;
  --theme-inter-bold-28-88-font-weight: 700;
  --theme-inter-bold-28-88-letter-spacing: 0px;
  --theme-inter-bold-28-88-line-height: 40px;
  --theme-inter-bold-28-88-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-28-88-upper-font-size: 28.875px;
  --theme-inter-bold-28-88-upper-font-style: normal;
  --theme-inter-bold-28-88-upper-font-weight: 700;
  --theme-inter-bold-28-88-upper-letter-spacing: 0px;
  --theme-inter-bold-28-88-upper-line-height: 38px;
  --theme-inter-bold-28-upper-font-family: "Inter", Helvetica;
  --theme-inter-bold-28-upper-font-size: 28px;
  --theme-inter-bold-28-upper-font-style: normal;
  --theme-inter-bold-28-upper-font-weight: 700;
  --theme-inter-bold-28-upper-letter-spacing: 0px;
  --theme-inter-bold-28-upper-line-height: 38px;
  --theme-inter-bold-29-53-font-family: "Inter", Helvetica;
  --theme-inter-bold-29-53-font-size: 29.5313px;
  --theme-inter-bold-29-53-font-style: normal;
  --theme-inter-bold-29-53-font-weight: 700;
  --theme-inter-bold-29-53-letter-spacing: 0px;
  --theme-inter-bold-29-53-line-height: 28px;
  --theme-inter-bold-29-65-font-family: "Inter", Helvetica;
  --theme-inter-bold-29-65-font-size: 29.6484px;
  --theme-inter-bold-29-65-font-style: normal;
  --theme-inter-bold-29-65-font-weight: 700;
  --theme-inter-bold-29-65-letter-spacing: 0px;
  --theme-inter-bold-29-65-line-height: 28px;
  --theme-inter-bold-30-38-font-family: "Inter", Helvetica;
  --theme-inter-bold-30-38-font-size: 30.375px;
  --theme-inter-bold-30-38-font-style: normal;
  --theme-inter-bold-30-38-font-weight: 700;
  --theme-inter-bold-30-38-letter-spacing: 0px;
  --theme-inter-bold-30-38-line-height: 40px;
  --theme-inter-bold-30-font-family: "Inter", Helvetica;
  --theme-inter-bold-30-font-size: 30px;
  --theme-inter-bold-30-font-style: normal;
  --theme-inter-bold-30-font-weight: 700;
  --theme-inter-bold-30-letter-spacing: 0px;
  --theme-inter-bold-30-line-height: 28px;
  --theme-inter-bold-31-25-font-family: "Inter", Helvetica;
  --theme-inter-bold-31-25-font-size: 31.25px;
  --theme-inter-bold-31-25-font-style: normal;
  --theme-inter-bold-31-25-font-weight: 700;
  --theme-inter-bold-31-25-letter-spacing: 0px;
  --theme-inter-bold-31-25-line-height: 40px;
  --theme-inter-bold-31-font-family: "Inter", Helvetica;
  --theme-inter-bold-31-font-size: 31px;
  --theme-inter-bold-31-font-style: normal;
  --theme-inter-bold-31-font-weight: 700;
  --theme-inter-bold-31-letter-spacing: 0px;
  --theme-inter-bold-31-line-height: 40px;
  --theme-inter-bold-33-69-font-family: "Inter", Helvetica;
  --theme-inter-bold-33-69-font-size: 33.6875px;
  --theme-inter-bold-33-69-font-style: normal;
  --theme-inter-bold-33-69-font-weight: 700;
  --theme-inter-bold-33-69-letter-spacing: 0px;
  --theme-inter-bold-33-69-line-height: 56px;
  --theme-inter-bold-37-34-font-family: "Inter", Helvetica;
  --theme-inter-bold-37-34-font-size: 37.3438px;
  --theme-inter-bold-37-34-font-style: normal;
  --theme-inter-bold-37-34-font-weight: 700;
  --theme-inter-bold-37-34-letter-spacing: 0px;
  --theme-inter-bold-37-34-line-height: 48px;
  --theme-inter-bold-44-font-family: "Inter", Helvetica;
  --theme-inter-bold-44-font-size: 44px;
  --theme-inter-bold-44-font-style: normal;
  --theme-inter-bold-44-font-weight: 700;
  --theme-inter-bold-44-letter-spacing: 0px;
  --theme-inter-bold-44-line-height: 59px;
  --theme-inter-bold-48-font-family: "Inter", Helvetica;
  --theme-inter-bold-48-font-size: 48px;
  --theme-inter-bold-48-font-style: normal;
  --theme-inter-bold-48-font-weight: 700;
  --theme-inter-bold-48-letter-spacing: 0px;
  --theme-inter-bold-48-line-height: 60px;
  --theme-inter-bold-58-78-font-family: "Inter", Helvetica;
  --theme-inter-bold-58-78-font-size: 58.7813px;
  --theme-inter-bold-58-78-font-style: normal;
  --theme-inter-bold-58-78-font-weight: 700;
  --theme-inter-bold-58-78-letter-spacing: 0px;
  --theme-inter-bold-58-78-line-height: 40px;
  --theme-inter-bold-64-font-family: "Inter", Helvetica;
  --theme-inter-bold-64-font-size: 64px;
  --theme-inter-bold-64-font-style: normal;
  --theme-inter-bold-64-font-weight: 700;
  --theme-inter-bold-64-letter-spacing: 0px;
  --theme-inter-bold-64-line-height: 64px;
  --theme-inter-bold-72-font-family: "Inter", Helvetica;
  --theme-inter-bold-72-font-size: 72px;
  --theme-inter-bold-72-font-style: normal;
  --theme-inter-bold-72-font-weight: 700;
  --theme-inter-bold-72-letter-spacing: 0px;
  --theme-inter-bold-72-line-height: 60px;
  --theme-inter-bold-8-77-font-family: "Inter", Helvetica;
  --theme-inter-bold-8-77-font-size: 8.76563px;
  --theme-inter-bold-8-77-font-style: normal;
  --theme-inter-bold-8-77-font-weight: 700;
  --theme-inter-bold-8-77-letter-spacing: 0px;
  --theme-inter-bold-8-77-line-height: 12px;
  --theme-inter-bold-8-94-font-family: "Inter", Helvetica;
  --theme-inter-bold-8-94-font-size: 8.9375px;
  --theme-inter-bold-8-94-font-style: normal;
  --theme-inter-bold-8-94-font-weight: 700;
  --theme-inter-bold-8-94-letter-spacing: 0px;
  --theme-inter-bold-8-94-line-height: 12px;
  --theme-inter-bold-80-font-family: "Inter", Helvetica;
  --theme-inter-bold-80-font-size: 80px;
  --theme-inter-bold-80-font-style: normal;
  --theme-inter-bold-80-font-weight: 700;
  --theme-inter-bold-80-letter-spacing: 0px;
  --theme-inter-bold-80-line-height: 80px;
  --theme-inter-bold-9-11-font-family: "Inter", Helvetica;
  --theme-inter-bold-9-11-font-size: 9.10938px;
  --theme-inter-bold-9-11-font-style: normal;
  --theme-inter-bold-9-11-font-weight: 700;
  --theme-inter-bold-9-11-letter-spacing: 0px;
  --theme-inter-bold-9-11-line-height: 12px;
  --theme-inter-bold-9-28-font-family: "Inter", Helvetica;
  --theme-inter-bold-9-28-font-size: 9.28125px;
  --theme-inter-bold-9-28-font-style: normal;
  --theme-inter-bold-9-28-font-weight: 700;
  --theme-inter-bold-9-28-letter-spacing: 0px;
  --theme-inter-bold-9-28-line-height: 12px;
  --theme-inter-bold-9-63-font-family: "Inter", Helvetica;
  --theme-inter-bold-9-63-font-size: 9.625px;
  --theme-inter-bold-9-63-font-style: normal;
  --theme-inter-bold-9-63-font-weight: 700;
  --theme-inter-bold-9-63-letter-spacing: 0px;
  --theme-inter-bold-9-63-line-height: 12px;
  --theme-inter-bold-9-8-font-family: "Inter", Helvetica;
  --theme-inter-bold-9-8-font-size: 9.79688px;
  --theme-inter-bold-9-8-font-style: normal;
  --theme-inter-bold-9-8-font-weight: 700;
  --theme-inter-bold-9-8-letter-spacing: 0px;
  --theme-inter-bold-9-8-line-height: 12px;
  --theme-inter-extra-bold-16-87-font-family: "Inter", Helvetica;
  --theme-inter-extra-bold-16-87-font-size: 16.8672px;
  --theme-inter-extra-bold-16-87-font-style: normal;
  --theme-inter-extra-bold-16-87-font-weight: 800;
  --theme-inter-extra-bold-16-87-letter-spacing: 0px;
  --theme-inter-extra-bold-16-87-line-height: 21px;
  --theme-inter-extra-bold-16-87-title-font-family: "Inter", Helvetica;
  --theme-inter-extra-bold-16-87-title-font-size: 16.8672px;
  --theme-inter-extra-bold-16-87-title-font-style: normal;
  --theme-inter-extra-bold-16-87-title-font-weight: 800;
  --theme-inter-extra-bold-16-87-title-letter-spacing: 0px;
  --theme-inter-extra-bold-16-87-title-line-height: 21px;
  --theme-inter-italic-16-59-font-family: "Inter", Helvetica;
  --theme-inter-italic-16-59-font-size: 16.5938px;
  --theme-inter-italic-16-59-font-style: italic;
  --theme-inter-italic-16-59-font-weight: 400;
  --theme-inter-italic-16-59-letter-spacing: 0px;
  --theme-inter-italic-16-59-line-height: 28px;
  --theme-inter-italic-16-73-font-family: "Inter", Helvetica;
  --theme-inter-italic-16-73-font-size: 16.7344px;
  --theme-inter-italic-16-73-font-style: italic;
  --theme-inter-italic-16-73-font-weight: 400;
  --theme-inter-italic-16-73-letter-spacing: 0px;
  --theme-inter-italic-16-73-line-height: 28px;
  --theme-inter-italic-16-88-font-family: "Inter", Helvetica;
  --theme-inter-italic-16-88-font-size: 16.875px;
  --theme-inter-italic-16-88-font-style: italic;
  --theme-inter-italic-16-88-font-weight: 400;
  --theme-inter-italic-16-88-letter-spacing: 0px;
  --theme-inter-italic-16-88-line-height: 28px;
  --theme-inter-italic-17-16-font-family: "Inter", Helvetica;
  --theme-inter-italic-17-16-font-size: 17.1563px;
  --theme-inter-italic-17-16-font-style: italic;
  --theme-inter-italic-17-16-font-weight: 400;
  --theme-inter-italic-17-16-letter-spacing: 0px;
  --theme-inter-italic-17-16-line-height: 28px;
  --theme-inter-medium-15-25-font-family: "Inter", Helvetica;
  --theme-inter-medium-15-25-font-size: 15.25px;
  --theme-inter-medium-15-25-font-style: normal;
  --theme-inter-medium-15-25-font-weight: 500;
  --theme-inter-medium-15-25-letter-spacing: .286px;
  --theme-inter-medium-15-25-line-height: 24px;
  --theme-inter-medium-15-25-title-font-family: "Inter", Helvetica;
  --theme-inter-medium-15-25-title-font-size: 15.25px;
  --theme-inter-medium-15-25-title-font-style: normal;
  --theme-inter-medium-15-25-title-font-weight: 500;
  --theme-inter-medium-15-25-title-letter-spacing: .286px;
  --theme-inter-medium-15-25-title-line-height: 24px;
  --theme-inter-regular-10-48-font-family: "Inter", Helvetica;
  --theme-inter-regular-10-48-font-size: 10.4844px;
  --theme-inter-regular-10-48-font-style: normal;
  --theme-inter-regular-10-48-font-weight: 400;
  --theme-inter-regular-10-48-letter-spacing: 0px;
  --theme-inter-regular-10-48-line-height: 16px;
  --theme-inter-regular-10-66-font-family: "Inter", Helvetica;
  --theme-inter-regular-10-66-font-size: 10.6563px;
  --theme-inter-regular-10-66-font-style: normal;
  --theme-inter-regular-10-66-font-weight: 400;
  --theme-inter-regular-10-66-letter-spacing: 0px;
  --theme-inter-regular-10-66-line-height: 16px;
  --theme-inter-regular-10-88-font-family: "Inter", Helvetica;
  --theme-inter-regular-10-88-font-size: 10.875px;
  --theme-inter-regular-10-88-font-style: normal;
  --theme-inter-regular-10-88-font-weight: 400;
  --theme-inter-regular-10-88-letter-spacing: 0px;
  --theme-inter-regular-10-88-line-height: 17.14px;
  --theme-inter-regular-11-05-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-05-font-size: 11.0469px;
  --theme-inter-regular-11-05-font-style: normal;
  --theme-inter-regular-11-05-font-weight: 400;
  --theme-inter-regular-11-05-letter-spacing: 0px;
  --theme-inter-regular-11-05-line-height: 21px;
  --theme-inter-regular-11-06-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-06-font-size: 11.0625px;
  --theme-inter-regular-11-06-font-style: normal;
  --theme-inter-regular-11-06-font-weight: 400;
  --theme-inter-regular-11-06-letter-spacing: 0px;
  --theme-inter-regular-11-06-line-height: 17.14px;
  --theme-inter-regular-11-25-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-25-font-size: 11.25px;
  --theme-inter-regular-11-25-font-style: normal;
  --theme-inter-regular-11-25-font-weight: 400;
  --theme-inter-regular-11-25-letter-spacing: 0px;
  --theme-inter-regular-11-25-line-height: 40px;
  --theme-inter-regular-11-27-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-27-font-size: 11.2656px;
  --theme-inter-regular-11-27-font-style: normal;
  --theme-inter-regular-11-27-font-weight: 400;
  --theme-inter-regular-11-27-letter-spacing: 0px;
  --theme-inter-regular-11-27-line-height: 24px;
  --theme-inter-regular-11-44-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-44-font-size: 11.4375px;
  --theme-inter-regular-11-44-font-style: normal;
  --theme-inter-regular-11-44-font-weight: 400;
  --theme-inter-regular-11-44-letter-spacing: 0px;
  --theme-inter-regular-11-44-line-height: 14px;
  --theme-inter-regular-11-48-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-48-font-size: 11.4844px;
  --theme-inter-regular-11-48-font-style: normal;
  --theme-inter-regular-11-48-font-weight: 400;
  --theme-inter-regular-11-48-letter-spacing: 0px;
  --theme-inter-regular-11-48-line-height: 21px;
  --theme-inter-regular-11-59-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-59-font-size: 11.5938px;
  --theme-inter-regular-11-59-font-style: normal;
  --theme-inter-regular-11-59-font-weight: 400;
  --theme-inter-regular-11-59-letter-spacing: 0px;
  --theme-inter-regular-11-59-line-height: 21px;
  --theme-inter-regular-11-63-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-63-font-size: 11.625px;
  --theme-inter-regular-11-63-font-style: normal;
  --theme-inter-regular-11-63-font-weight: 400;
  --theme-inter-regular-11-63-letter-spacing: 0px;
  --theme-inter-regular-11-63-line-height: 21px;
  --theme-inter-regular-11-63-title-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-63-title-font-size: 11.625px;
  --theme-inter-regular-11-63-title-font-style: normal;
  --theme-inter-regular-11-63-title-font-weight: 400;
  --theme-inter-regular-11-63-title-letter-spacing: 0px;
  --theme-inter-regular-11-63-title-line-height: 18px;
  --theme-inter-regular-11-63-upper-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-63-upper-font-size: 11.625px;
  --theme-inter-regular-11-63-upper-font-style: normal;
  --theme-inter-regular-11-63-upper-font-weight: 400;
  --theme-inter-regular-11-63-upper-letter-spacing: 0px;
  --theme-inter-regular-11-63-upper-line-height: 12px;
  --theme-inter-regular-11-7-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-7-font-size: 11.7031px;
  --theme-inter-regular-11-7-font-style: normal;
  --theme-inter-regular-11-7-font-weight: 400;
  --theme-inter-regular-11-7-letter-spacing: 0px;
  --theme-inter-regular-11-7-line-height: 21px;
  --theme-inter-regular-11-81-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-81-font-size: 11.8125px;
  --theme-inter-regular-11-81-font-style: normal;
  --theme-inter-regular-11-81-font-weight: 400;
  --theme-inter-regular-11-81-letter-spacing: 0px;
  --theme-inter-regular-11-81-line-height: 21px;
  --theme-inter-regular-11-81-upper-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-81-upper-font-size: 11.8125px;
  --theme-inter-regular-11-81-upper-font-style: normal;
  --theme-inter-regular-11-81-upper-font-weight: 400;
  --theme-inter-regular-11-81-upper-letter-spacing: 0px;
  --theme-inter-regular-11-81-upper-line-height: 12px;
  --theme-inter-regular-11-92-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-92-font-size: 11.9219px;
  --theme-inter-regular-11-92-font-style: normal;
  --theme-inter-regular-11-92-font-weight: 400;
  --theme-inter-regular-11-92-letter-spacing: 0px;
  --theme-inter-regular-11-92-line-height: 24px;
  --theme-inter-regular-11-font-family: "Inter", Helvetica;
  --theme-inter-regular-11-font-size: 11px;
  --theme-inter-regular-11-font-style: normal;
  --theme-inter-regular-11-font-weight: 400;
  --theme-inter-regular-11-letter-spacing: 0px;
  --theme-inter-regular-11-line-height: 16px;
  --theme-inter-regular-12-03-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-03-font-size: 12.0313px;
  --theme-inter-regular-12-03-font-style: normal;
  --theme-inter-regular-12-03-font-weight: 400;
  --theme-inter-regular-12-03-letter-spacing: 0px;
  --theme-inter-regular-12-03-line-height: 21px;
  --theme-inter-regular-12-47-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-47-font-size: 12.4688px;
  --theme-inter-regular-12-47-font-style: normal;
  --theme-inter-regular-12-47-font-weight: 400;
  --theme-inter-regular-12-47-letter-spacing: 0px;
  --theme-inter-regular-12-47-line-height: 24px;
  --theme-inter-regular-12-63-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-63-font-size: 12.625px;
  --theme-inter-regular-12-63-font-style: normal;
  --theme-inter-regular-12-63-font-weight: 400;
  --theme-inter-regular-12-63-letter-spacing: 0px;
  --theme-inter-regular-12-63-line-height: 24px;
  --theme-inter-regular-12-69-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-69-font-size: 12.6875px;
  --theme-inter-regular-12-69-font-style: normal;
  --theme-inter-regular-12-69-font-weight: 400;
  --theme-inter-regular-12-69-letter-spacing: 0px;
  --theme-inter-regular-12-69-line-height: 20px;
  --theme-inter-regular-12-75-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-75-font-size: 12.75px;
  --theme-inter-regular-12-75-font-style: normal;
  --theme-inter-regular-12-75-font-weight: 400;
  --theme-inter-regular-12-75-letter-spacing: 0px;
  --theme-inter-regular-12-75-line-height: 22.86px;
  --theme-inter-regular-12-88-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-88-font-size: 12.875px;
  --theme-inter-regular-12-88-font-style: normal;
  --theme-inter-regular-12-88-font-weight: 400;
  --theme-inter-regular-12-88-letter-spacing: 0px;
  --theme-inter-regular-12-88-line-height: 22.86px;
  --theme-inter-regular-12-91-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-91-font-size: 12.9063px;
  --theme-inter-regular-12-91-font-style: normal;
  --theme-inter-regular-12-91-font-weight: 400;
  --theme-inter-regular-12-91-letter-spacing: 0px;
  --theme-inter-regular-12-91-line-height: 20px;
  --theme-inter-regular-12-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-font-size: 12px;
  --theme-inter-regular-12-font-style: normal;
  --theme-inter-regular-12-font-weight: 400;
  --theme-inter-regular-12-letter-spacing: 0px;
  --theme-inter-regular-12-line-height: 17.14px;
  --theme-inter-regular-12-upper-font-family: "Inter", Helvetica;
  --theme-inter-regular-12-upper-font-size: 12px;
  --theme-inter-regular-12-upper-font-style: normal;
  --theme-inter-regular-12-upper-font-weight: 400;
  --theme-inter-regular-12-upper-letter-spacing: 0px;
  --theme-inter-regular-12-upper-line-height: 12px;
  --theme-inter-regular-13-02-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-02-font-size: 13.0156px;
  --theme-inter-regular-13-02-font-style: normal;
  --theme-inter-regular-13-02-font-weight: 400;
  --theme-inter-regular-13-02-letter-spacing: 0px;
  --theme-inter-regular-13-02-line-height: 18.9px;
  --theme-inter-regular-13-13-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-13-font-size: 13.125px;
  --theme-inter-regular-13-13-font-style: normal;
  --theme-inter-regular-13-13-font-weight: 400;
  --theme-inter-regular-13-13-letter-spacing: 0px;
  --theme-inter-regular-13-13-line-height: 22.86px;
  --theme-inter-regular-13-13-title-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-13-title-font-size: 13.125px;
  --theme-inter-regular-13-13-title-font-style: normal;
  --theme-inter-regular-13-13-title-font-weight: 400;
  --theme-inter-regular-13-13-title-letter-spacing: 0px;
  --theme-inter-regular-13-13-title-line-height: 20px;
  --theme-inter-regular-13-23-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-23-font-size: 13.2344px;
  --theme-inter-regular-13-23-font-style: normal;
  --theme-inter-regular-13-23-font-weight: 400;
  --theme-inter-regular-13-23-letter-spacing: 0px;
  --theme-inter-regular-13-23-line-height: 18.9px;
  --theme-inter-regular-13-23-title-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-23-title-font-size: 13.2344px;
  --theme-inter-regular-13-23-title-font-style: normal;
  --theme-inter-regular-13-23-title-font-weight: 400;
  --theme-inter-regular-13-23-title-letter-spacing: 0px;
  --theme-inter-regular-13-23-title-line-height: 20px;
  --theme-inter-regular-13-25-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-25-font-size: 13.25px;
  --theme-inter-regular-13-25-font-style: normal;
  --theme-inter-regular-13-25-font-weight: 400;
  --theme-inter-regular-13-25-letter-spacing: 0px;
  --theme-inter-regular-13-25-line-height: 22.86px;
  --theme-inter-regular-13-34-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-34-font-size: 13.3438px;
  --theme-inter-regular-13-34-font-style: normal;
  --theme-inter-regular-13-34-font-weight: 400;
  --theme-inter-regular-13-34-letter-spacing: 0px;
  --theme-inter-regular-13-34-line-height: 21px;
  --theme-inter-regular-13-34-title-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-34-title-font-size: 13.3438px;
  --theme-inter-regular-13-34-title-font-style: normal;
  --theme-inter-regular-13-34-title-font-weight: 400;
  --theme-inter-regular-13-34-title-letter-spacing: 0px;
  --theme-inter-regular-13-34-title-line-height: 20px;
  --theme-inter-regular-13-38-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-38-font-size: 13.375px;
  --theme-inter-regular-13-38-font-style: normal;
  --theme-inter-regular-13-38-font-weight: 400;
  --theme-inter-regular-13-38-letter-spacing: 0px;
  --theme-inter-regular-13-38-line-height: 22.86px;
  --theme-inter-regular-13-45-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-45-font-size: 13.4531px;
  --theme-inter-regular-13-45-font-style: normal;
  --theme-inter-regular-13-45-font-weight: 400;
  --theme-inter-regular-13-45-letter-spacing: 0px;
  --theme-inter-regular-13-45-line-height: 46px;
  --theme-inter-regular-13-56-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-56-font-size: 13.5625px;
  --theme-inter-regular-13-56-font-style: normal;
  --theme-inter-regular-13-56-font-weight: 400;
  --theme-inter-regular-13-56-letter-spacing: 0px;
  --theme-inter-regular-13-56-line-height: 20px;
  --theme-inter-regular-13-67-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-67-font-size: 13.6719px;
  --theme-inter-regular-13-67-font-style: normal;
  --theme-inter-regular-13-67-font-weight: 400;
  --theme-inter-regular-13-67-letter-spacing: 0px;
  --theme-inter-regular-13-67-line-height: 20px;
  --theme-inter-regular-13-78-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-78-font-size: 13.7813px;
  --theme-inter-regular-13-78-font-style: normal;
  --theme-inter-regular-13-78-font-weight: 400;
  --theme-inter-regular-13-78-letter-spacing: 0px;
  --theme-inter-regular-13-78-line-height: 46px;
  --theme-inter-regular-13-89-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-89-font-size: 13.8906px;
  --theme-inter-regular-13-89-font-style: normal;
  --theme-inter-regular-13-89-font-weight: 400;
  --theme-inter-regular-13-89-letter-spacing: 0px;
  --theme-inter-regular-13-89-line-height: 20px;
  --theme-inter-regular-13-font-family: "Inter", Helvetica;
  --theme-inter-regular-13-font-size: 13px;
  --theme-inter-regular-13-font-style: normal;
  --theme-inter-regular-13-font-weight: 400;
  --theme-inter-regular-13-letter-spacing: 0px;
  --theme-inter-regular-13-line-height: 22.86px;
  --theme-inter-regular-14-75-font-family: "Inter", Helvetica;
  --theme-inter-regular-14-75-font-size: 14.75px;
  --theme-inter-regular-14-75-font-style: normal;
  --theme-inter-regular-14-75-font-weight: 400;
  --theme-inter-regular-14-75-letter-spacing: 0px;
  --theme-inter-regular-14-75-line-height: 28px;
  --theme-inter-regular-14-75-underline-font-family: "Inter", Helvetica;
  --theme-inter-regular-14-75-underline-font-size: 14.75px;
  --theme-inter-regular-14-75-underline-font-style: normal;
  --theme-inter-regular-14-75-underline-font-weight: 400;
  --theme-inter-regular-14-75-underline-letter-spacing: 0px;
  --theme-inter-regular-14-75-underline-line-height: 28px;
  --theme-inter-regular-14-88-font-family: "Inter", Helvetica;
  --theme-inter-regular-14-88-font-size: 14.875px;
  --theme-inter-regular-14-88-font-style: normal;
  --theme-inter-regular-14-88-font-weight: 400;
  --theme-inter-regular-14-88-letter-spacing: 0px;
  --theme-inter-regular-14-88-line-height: 24px;
  --theme-inter-regular-14-font-family: "Inter", Helvetica;
  --theme-inter-regular-14-font-size: 14px;
  --theme-inter-regular-14-font-style: normal;
  --theme-inter-regular-14-font-weight: 400;
  --theme-inter-regular-14-letter-spacing: 0px;
  --theme-inter-regular-14-line-height: 24px;
  --theme-inter-regular-15-13-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-13-font-size: 15.125px;
  --theme-inter-regular-15-13-font-style: normal;
  --theme-inter-regular-15-13-font-weight: 400;
  --theme-inter-regular-15-13-letter-spacing: 0px;
  --theme-inter-regular-15-13-line-height: 22.86px;
  --theme-inter-regular-15-13-underline-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-13-underline-font-size: 15.125px;
  --theme-inter-regular-15-13-underline-font-style: normal;
  --theme-inter-regular-15-13-underline-font-weight: 400;
  --theme-inter-regular-15-13-underline-letter-spacing: 0px;
  --theme-inter-regular-15-13-underline-line-height: 28px;
  --theme-inter-regular-15-25-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-25-font-size: 15.25px;
  --theme-inter-regular-15-25-font-style: normal;
  --theme-inter-regular-15-25-font-weight: 400;
  --theme-inter-regular-15-25-letter-spacing: 0px;
  --theme-inter-regular-15-25-line-height: 28px;
  --theme-inter-regular-15-38-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-38-font-size: 15.375px;
  --theme-inter-regular-15-38-font-style: normal;
  --theme-inter-regular-15-38-font-weight: 400;
  --theme-inter-regular-15-38-letter-spacing: 0px;
  --theme-inter-regular-15-38-line-height: 22.86px;
  --theme-inter-regular-15-38-title-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-38-title-font-size: 15.375px;
  --theme-inter-regular-15-38-title-font-style: normal;
  --theme-inter-regular-15-38-title-font-weight: 400;
  --theme-inter-regular-15-38-title-letter-spacing: .286px;
  --theme-inter-regular-15-38-title-line-height: 20px;
  --theme-inter-regular-15-5-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-5-font-size: 15.5px;
  --theme-inter-regular-15-5-font-style: normal;
  --theme-inter-regular-15-5-font-weight: 400;
  --theme-inter-regular-15-5-letter-spacing: 0px;
  --theme-inter-regular-15-5-line-height: 28px;
  --theme-inter-regular-15-63-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-63-font-size: 15.625px;
  --theme-inter-regular-15-63-font-style: normal;
  --theme-inter-regular-15-63-font-weight: 400;
  --theme-inter-regular-15-63-letter-spacing: 0px;
  --theme-inter-regular-15-63-line-height: 24px;
  --theme-inter-regular-15-75-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-75-font-size: 15.75px;
  --theme-inter-regular-15-75-font-style: normal;
  --theme-inter-regular-15-75-font-weight: 400;
  --theme-inter-regular-15-75-letter-spacing: 0px;
  --theme-inter-regular-15-75-line-height: 24px;
  --theme-inter-regular-15-78-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-78-font-size: 15.7813px;
  --theme-inter-regular-15-78-font-style: normal;
  --theme-inter-regular-15-78-font-weight: 400;
  --theme-inter-regular-15-78-letter-spacing: 0px;
  --theme-inter-regular-15-78-line-height: 30px;
  --theme-inter-regular-15-88-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-88-font-size: 15.875px;
  --theme-inter-regular-15-88-font-style: normal;
  --theme-inter-regular-15-88-font-weight: 400;
  --theme-inter-regular-15-88-letter-spacing: 0px;
  --theme-inter-regular-15-88-line-height: 22.86px;
  --theme-inter-regular-15-94-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-94-font-size: 15.9375px;
  --theme-inter-regular-15-94-font-style: normal;
  --theme-inter-regular-15-94-font-weight: 400;
  --theme-inter-regular-15-94-letter-spacing: 0px;
  --theme-inter-regular-15-94-line-height: 30px;
  --theme-inter-regular-15-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-font-size: 15px;
  --theme-inter-regular-15-font-style: normal;
  --theme-inter-regular-15-font-weight: 400;
  --theme-inter-regular-15-letter-spacing: 0px;
  --theme-inter-regular-15-line-height: 28px;
  --theme-inter-regular-15-underline-font-family: "Inter", Helvetica;
  --theme-inter-regular-15-underline-font-size: 15px;
  --theme-inter-regular-15-underline-font-style: normal;
  --theme-inter-regular-15-underline-font-weight: 400;
  --theme-inter-regular-15-underline-letter-spacing: 0px;
  --theme-inter-regular-15-underline-line-height: 28px;
  --theme-inter-regular-16-07-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-07-font-size: 16.0703px;
  --theme-inter-regular-16-07-font-style: normal;
  --theme-inter-regular-16-07-font-weight: 400;
  --theme-inter-regular-16-07-letter-spacing: 0px;
  --theme-inter-regular-16-07-line-height: 24px;
  --theme-inter-regular-16-07-title-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-07-title-font-size: 16.0703px;
  --theme-inter-regular-16-07-title-font-style: normal;
  --theme-inter-regular-16-07-title-font-weight: 400;
  --theme-inter-regular-16-07-title-letter-spacing: 0px;
  --theme-inter-regular-16-07-title-line-height: 24px;
  --theme-inter-regular-16-09-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-09-font-size: 16.0938px;
  --theme-inter-regular-16-09-font-style: normal;
  --theme-inter-regular-16-09-font-weight: 400;
  --theme-inter-regular-16-09-letter-spacing: 0px;
  --theme-inter-regular-16-09-line-height: 30px;
  --theme-inter-regular-16-2-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-2-font-size: 16.2031px;
  --theme-inter-regular-16-2-font-style: normal;
  --theme-inter-regular-16-2-font-weight: 400;
  --theme-inter-regular-16-2-letter-spacing: 0px;
  --theme-inter-regular-16-2-line-height: 30px;
  --theme-inter-regular-16-2-title-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-2-title-font-size: 16.2031px;
  --theme-inter-regular-16-2-title-font-style: normal;
  --theme-inter-regular-16-2-title-font-weight: 400;
  --theme-inter-regular-16-2-title-letter-spacing: 0px;
  --theme-inter-regular-16-2-title-line-height: 24px;
  --theme-inter-regular-16-25-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-25-font-size: 16.25px;
  --theme-inter-regular-16-25-font-style: normal;
  --theme-inter-regular-16-25-font-weight: 400;
  --theme-inter-regular-16-25-letter-spacing: 0px;
  --theme-inter-regular-16-25-line-height: 30px;
  --theme-inter-regular-16-34-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-34-font-size: 16.3359px;
  --theme-inter-regular-16-34-font-style: normal;
  --theme-inter-regular-16-34-font-weight: 400;
  --theme-inter-regular-16-34-letter-spacing: 0px;
  --theme-inter-regular-16-34-line-height: 30px;
  --theme-inter-regular-16-41-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-41-font-size: 16.4063px;
  --theme-inter-regular-16-41-font-style: normal;
  --theme-inter-regular-16-41-font-weight: 400;
  --theme-inter-regular-16-41-letter-spacing: 0px;
  --theme-inter-regular-16-41-line-height: 30px;
  --theme-inter-regular-16-47-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-47-font-size: 16.4688px;
  --theme-inter-regular-16-47-font-style: normal;
  --theme-inter-regular-16-47-font-weight: 400;
  --theme-inter-regular-16-47-letter-spacing: 0px;
  --theme-inter-regular-16-47-line-height: 30px;
  --theme-inter-regular-16-56-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-56-font-size: 16.5625px;
  --theme-inter-regular-16-56-font-style: normal;
  --theme-inter-regular-16-56-font-weight: 400;
  --theme-inter-regular-16-56-letter-spacing: 0px;
  --theme-inter-regular-16-56-line-height: 30px;
  --theme-inter-regular-16-6-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-6-font-size: 16.6016px;
  --theme-inter-regular-16-6-font-style: normal;
  --theme-inter-regular-16-6-font-weight: 400;
  --theme-inter-regular-16-6-letter-spacing: 0px;
  --theme-inter-regular-16-6-line-height: 30px;
  --theme-inter-regular-16-72-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-72-font-size: 16.7188px;
  --theme-inter-regular-16-72-font-style: normal;
  --theme-inter-regular-16-72-font-weight: 400;
  --theme-inter-regular-16-72-letter-spacing: 0px;
  --theme-inter-regular-16-72-line-height: 30px;
  --theme-inter-regular-16-73-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-73-font-size: 16.7344px;
  --theme-inter-regular-16-73-font-style: normal;
  --theme-inter-regular-16-73-font-weight: 400;
  --theme-inter-regular-16-73-letter-spacing: 0px;
  --theme-inter-regular-16-73-line-height: 30px;
  --theme-inter-regular-16-88-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-88-font-size: 16.875px;
  --theme-inter-regular-16-88-font-style: normal;
  --theme-inter-regular-16-88-font-weight: 400;
  --theme-inter-regular-16-88-letter-spacing: 0px;
  --theme-inter-regular-16-88-line-height: 28px;
  --theme-inter-regular-16-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-font-size: 16px;
  --theme-inter-regular-16-font-style: normal;
  --theme-inter-regular-16-font-weight: 400;
  --theme-inter-regular-16-letter-spacing: 0px;
  --theme-inter-regular-16-line-height: 28px;
  --theme-inter-regular-16-underline-font-family: "Inter", Helvetica;
  --theme-inter-regular-16-underline-font-size: 16px;
  --theme-inter-regular-16-underline-font-style: normal;
  --theme-inter-regular-16-underline-font-weight: 400;
  --theme-inter-regular-16-underline-letter-spacing: 0px;
  --theme-inter-regular-16-underline-line-height: 28px;
  --theme-inter-regular-17-02-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-02-font-size: 17.0156px;
  --theme-inter-regular-17-02-font-style: normal;
  --theme-inter-regular-17-02-font-weight: 400;
  --theme-inter-regular-17-02-letter-spacing: 0px;
  --theme-inter-regular-17-02-line-height: 28px;
  --theme-inter-regular-17-03-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-03-font-size: 17.0313px;
  --theme-inter-regular-17-03-font-style: normal;
  --theme-inter-regular-17-03-font-weight: 400;
  --theme-inter-regular-17-03-letter-spacing: 0px;
  --theme-inter-regular-17-03-line-height: 30px;
  --theme-inter-regular-17-16-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-16-font-size: 17.1563px;
  --theme-inter-regular-17-16-font-style: normal;
  --theme-inter-regular-17-16-font-weight: 400;
  --theme-inter-regular-17-16-letter-spacing: 0px;
  --theme-inter-regular-17-16-line-height: 28px;
  --theme-inter-regular-17-3-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-3-font-size: 17.2969px;
  --theme-inter-regular-17-3-font-style: normal;
  --theme-inter-regular-17-3-font-weight: 400;
  --theme-inter-regular-17-3-letter-spacing: 0px;
  --theme-inter-regular-17-3-line-height: 28px;
  --theme-inter-regular-17-44-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-44-font-size: 17.4375px;
  --theme-inter-regular-17-44-font-style: normal;
  --theme-inter-regular-17-44-font-weight: 400;
  --theme-inter-regular-17-44-letter-spacing: 0px;
  --theme-inter-regular-17-44-line-height: 28px;
  --theme-inter-regular-17-58-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-58-font-size: 17.5781px;
  --theme-inter-regular-17-58-font-style: normal;
  --theme-inter-regular-17-58-font-weight: 400;
  --theme-inter-regular-17-58-letter-spacing: 0px;
  --theme-inter-regular-17-58-line-height: 28px;
  --theme-inter-regular-17-72-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-72-font-size: 17.7188px;
  --theme-inter-regular-17-72-font-style: normal;
  --theme-inter-regular-17-72-font-weight: 400;
  --theme-inter-regular-17-72-letter-spacing: 0px;
  --theme-inter-regular-17-72-line-height: 28px;
  --theme-inter-regular-17-font-family: "Inter", Helvetica;
  --theme-inter-regular-17-font-size: 17px;
  --theme-inter-regular-17-font-style: normal;
  --theme-inter-regular-17-font-weight: 400;
  --theme-inter-regular-17-letter-spacing: 0px;
  --theme-inter-regular-17-line-height: 30px;
  --theme-inter-regular-18-44-font-family: "Inter", Helvetica;
  --theme-inter-regular-18-44-font-size: 18.4375px;
  --theme-inter-regular-18-44-font-style: normal;
  --theme-inter-regular-18-44-font-weight: 400;
  --theme-inter-regular-18-44-letter-spacing: 0px;
  --theme-inter-regular-18-44-line-height: 30px;
  --theme-inter-regular-18-font-family: "Inter", Helvetica;
  --theme-inter-regular-18-font-size: 18px;
  --theme-inter-regular-18-font-style: normal;
  --theme-inter-regular-18-font-weight: 400;
  --theme-inter-regular-18-letter-spacing: 0px;
  --theme-inter-regular-18-line-height: 28px;
  --theme-inter-regular-19-69-font-family: "Inter", Helvetica;
  --theme-inter-regular-19-69-font-size: 19.6875px;
  --theme-inter-regular-19-69-font-style: normal;
  --theme-inter-regular-19-69-font-weight: 400;
  --theme-inter-regular-19-69-letter-spacing: 0px;
  --theme-inter-regular-19-69-line-height: 30px;
  --theme-inter-regular-20-06-font-family: "Inter", Helvetica;
  --theme-inter-regular-20-06-font-size: 20.0625px;
  --theme-inter-regular-20-06-font-style: normal;
  --theme-inter-regular-20-06-font-weight: 400;
  --theme-inter-regular-20-06-letter-spacing: 0px;
  --theme-inter-regular-20-06-line-height: 30px;
  --theme-inter-regular-22-13-font-family: "Inter", Helvetica;
  --theme-inter-regular-22-13-font-size: 22.125px;
  --theme-inter-regular-22-13-font-style: normal;
  --theme-inter-regular-22-13-font-weight: 400;
  --theme-inter-regular-22-13-letter-spacing: 0px;
  --theme-inter-regular-22-13-line-height: 24px;
  --theme-inter-regular-26-25-font-family: "Inter", Helvetica;
  --theme-inter-regular-26-25-font-size: 26.25px;
  --theme-inter-regular-26-25-font-style: normal;
  --theme-inter-regular-26-25-font-weight: 400;
  --theme-inter-regular-26-25-letter-spacing: 0px;
  --theme-inter-regular-26-25-line-height: 40px;
  --theme-inter-regular-26-75-font-family: "Inter", Helvetica;
  --theme-inter-regular-26-75-font-size: 26.75px;
  --theme-inter-regular-26-75-font-style: normal;
  --theme-inter-regular-26-75-font-weight: 400;
  --theme-inter-regular-26-75-letter-spacing: 0px;
  --theme-inter-regular-26-75-line-height: 40px;
  --theme-inter-regular-32-font-family: "Inter", Helvetica;
  --theme-inter-regular-32-font-size: 32px;
  --theme-inter-regular-32-font-style: normal;
  --theme-inter-regular-32-font-weight: 400;
  --theme-inter-regular-32-letter-spacing: 0px;
  --theme-inter-regular-32-line-height: 40px;
  --theme-inter-regular-40-font-family: "Inter", Helvetica;
  --theme-inter-regular-40-font-size: 40px;
  --theme-inter-regular-40-font-style: normal;
  --theme-inter-regular-40-font-weight: 400;
  --theme-inter-regular-40-letter-spacing: 0px;
  --theme-inter-regular-40-line-height: 48px;
  --theme-inter-regular-54-25-font-family: "Inter", Helvetica;
  --theme-inter-regular-54-25-font-size: 54.25px;
  --theme-inter-regular-54-25-font-style: normal;
  --theme-inter-regular-54-25-font-weight: 400;
  --theme-inter-regular-54-25-letter-spacing: 0px;
  --theme-inter-regular-54-25-line-height: 56px;
  --theme-inter-regular-9-38-font-family: "Inter", Helvetica;
  --theme-inter-regular-9-38-font-size: 9.375px;
  --theme-inter-regular-9-38-font-style: normal;
  --theme-inter-regular-9-38-font-weight: 400;
  --theme-inter-regular-9-38-letter-spacing: 0px;
  --theme-inter-regular-9-38-line-height: 20px;
  --theme-inter-regular-9-38-upper-font-family: "Inter", Helvetica;
  --theme-inter-regular-9-38-upper-font-size: 9.375px;
  --theme-inter-regular-9-38-upper-font-style: normal;
  --theme-inter-regular-9-38-upper-font-weight: 400;
  --theme-inter-regular-9-38-upper-letter-spacing: 0px;
  --theme-inter-regular-9-38-upper-line-height: 12px;
  --theme-inter-regular-9-53-font-family: "Inter", Helvetica;
  --theme-inter-regular-9-53-font-size: 9.53125px;
  --theme-inter-regular-9-53-font-style: normal;
  --theme-inter-regular-9-53-font-weight: 400;
  --theme-inter-regular-9-53-letter-spacing: .1px;
  --theme-inter-regular-9-53-line-height: 21px;
  --theme-inter-regular-9-69-font-family: "Inter", Helvetica;
  --theme-inter-regular-9-69-font-size: 9.6875px;
  --theme-inter-regular-9-69-font-style: normal;
  --theme-inter-regular-9-69-font-weight: 400;
  --theme-inter-regular-9-69-letter-spacing: 0px;
  --theme-inter-regular-9-69-line-height: 20px;
  --theme-inter-regular-9-75-font-family: "Inter", Helvetica;
  --theme-inter-regular-9-75-font-size: 9.75px;
  --theme-inter-regular-9-75-font-style: normal;
  --theme-inter-regular-9-75-font-weight: 400;
  --theme-inter-regular-9-75-letter-spacing: 0px;
  --theme-inter-regular-9-75-line-height: 16px;
  --themealabaster: #f8f8f8;
  --themealizarin-crimson: #ec1c24;
  --themealto: #dadada;
  --themeapple: #41a639;
  --themeastronaut: #2d4474;
  --themeblack: #000;
  --themeblack-10: #0000001a;
  --themeblack-25: #00000040;
  --themeblack-42: #0000006b;
  --themeblack-54: #0000008a;
  --themeblack-60: #0009;
  --themeblack-87: #000000de;
  --themeblack-white: #fffffe;
  --themeblue-violet: #735fb5;
  --themebunker: #040608;
  --themecalifornia: #f90;
  --themecardinal: #c4242f;
  --themecerulean: #00aecd;
  --themechateau-green: #34a853;
  --themecinnabar: #ea4335;
  --themecongress-blue: #005397;
  --themecornflower: #88d0e6;
  --themecornflower-blue: #4285f4;
  --themecrimson: #d80e15;
  --themeebony-clay: #1e282f;
  --themeendeavour: #0069b4;
  --themefire-bush: #eb8e32;
  --themefun-green: #006139;
  --themegallery: #efefef;
  --themegray: #929292;
  --themeguardsman-red: #c80a00;
  --themeheavy-metal: #1d1d1b;
  --themeiron: #c9cccf;
  --themela-palma: #138f16;
  --themematisse: #1f5da6;
  --thememercury: #e5e5e5;
  --thememillbrook: #604734;
  --thememine-shaft: #212121;
  --thememirage: #161d26;
  --thememonza: #d20515;
  --thememountain-meadow: #1fc779;
  --themenevada: #64686c;
  --themeoslo-gray: #888c90;
  --themeouter-space: #34383c;
  --themepomegranate: #f42531;
  --themeporcelain: #f4f5f6;
  --themeprussian-blue: #00205b;
  --themeracing-green: #0f1914;
  --themered: red;
  --themered-ribbon: #dd0741;
  --themerolling-stone: #71757a;
  --themesalem: #118751;
  --themescarpa-flow: #58585a;
  --themeschool-bus-yellow: #fd0;
  --themescooter: #3ab0d3;
  --themesea-green: #28844a;
  --themeselective-yellow: #fbbc04;
  --themeshark: #191e24;
  --themesilver: #cdcdcd;
  --themesilver-chalice: #b2b2b2;
  --themesilver-sand: #c9cacc;
  --themesupernova: #fcce00;
  --themeswamp: #000e14;
  --themethunderbird: #cc1719;
  --themetorch-red: #ff003c;
  --themetory-blue: #1456a2;
  --themetropical-rain-forest: #008149;
  --themewhite: #fff;
  --themewhite-02: #fff0;
  --themewhite-42: #ffffff6b;
  --themewhite-54: #ffffff8a;
  --themewhite-60: #fff9;
  --themewhite-75: #ffffffbf;
  --themewhite-87: #ffffffde;
  --themewhite-porcelain: #fff;
  --themewhite-swamp: #fff;
  --themewild-sand: #f5f5f5;
  --themeyellow: #ff0;
}
/*# sourceMappingURL=index.898a6bdb.css.map */
